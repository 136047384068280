import { Controller } from '@hotwired/stimulus'
import { ApiService } from '../../services/apiService'
import { lazyI18n } from '../../utils/lazyI18n'

// Connects to data-controller="import-site-tasks--form"
export default class extends Controller {
  static targets = ['selectProject', 'selectSite']

  connect() {
    this.handleSelectProjectChangeById(this.selectProjectTarget.value)
  }

  handleSelectProjectChange(event) {
    const projectId = event.target.value
    this.handleSelectProjectChangeById(projectId)
  }

  handleSelectProjectChangeById(projectId) {
    this.selectSiteTarget.innerHTML = ''
    document.getElementById('select_site_tasks').innerHTML = ''
    ApiService.get(`/projects/${projectId}/sites`).then((response) => {
      response.sites.forEach((site) => {
        this.selectSiteTarget.innerHTML += `<option value="${site.id}">${site.title}</option>`
      })
      this.handleSelectSiteChangeById(this.selectSiteTarget.value)
    })
  }

  handleSelectSiteChange(event) {
    const siteId = event.target.value
    this.handleSelectSiteChangeById(siteId)
  }

  handleSelectSiteChangeById(siteId) {
    document.getElementById('select_site_tasks').innerHTML = ''
    fetch(`/sites/${siteId}/import_site_tasks/${siteId}`, {
      headers: { Accept: 'text/vnd.turbo-stream.html' }
    })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  submitForm(event) {
    event.preventDefault()
    if (this.element.querySelectorAll('input[name="import_site_tasks[site_task_ids][]"]:checked').length < 1) {
      alert(lazyI18n('Seleziona almeno una lavorazione da importare.'))
      return
    }

    if (confirm(lazyI18n('Vuoi importare le lavorazioni selezionate?'))) {
      this.element.submit()
    }
  }
}
