import { Controller } from '@hotwired/stimulus'

const loadResources = (resources, callback) => {
  let loaded = []
  const totalResources = resources.scripts.length + resources.stylesheets.length
  const checkAllLoaded = (loadedType) => {
    loaded.push(loadedType)
    if (loaded.length === totalResources) {
      callback()
    }
  }

  resources.scripts.forEach(src => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => checkAllLoaded('script')
    document.body.appendChild(script)
  })

  resources.stylesheets.forEach(href => {
    const link = document.createElement('link')
    link.href = href
    link.rel = 'stylesheet'
    link.onload = () => checkAllLoaded('stylesheet')
    document.body.appendChild(link)
  })
}

// Connects to data-controller="utils--html-editor"
export default class extends Controller {
  connect() {
    if (typeof hugerte === 'undefined') {
      loadResources(
        {
          scripts: ['https://cdn.jsdelivr.net/npm/hugerte@1.0.6/hugerte.min.js'],
          stylesheets: ['https://cdn.jsdelivr.net/npm/hugerte@1.0.6/skins/ui/oxide/content.min.css']
        },
        this.textEditorLibLoaded
      )
    } else {
      this.textEditorLibLoaded()
    }
  }

  textEditorLibLoaded() {
    document.getElementById('loading-overlay').classList.remove('d-none')
    window.hugerte.init({
      selector: '.html_editor',
      plugins: 'accordion advlist anchor autolink autosave charmap code codesample directionality emoticons fullscreen help image insertdatetime link lists media nonbreaking pagebreak preview quickbars save searchreplace table template visualblocks visualchars wordcount',
      toolbar: 'template | insertvar | undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help | image',
      paste_data_images: true,
      toolbar_mode: 'floating',
      init_instance_callback: () => document.getElementById('loading-overlay').classList.add('d-none'),
      language: window.appData.locale,
      language_url: '/vendor/hugerte/langs7/locales/it.js',
      templates: [
        {
          title: 'Sopralluogo Tecnico',
          description: 'Template per documentare un sopralluogo tecnico in cantiere',
          content: '<h3>Sopralluogo Tecnico - {{user.full_name}}</h3>' +
            '<p><strong>Osservazioni:</strong></p>' +
            '<ul>' +
            '<li>Verifica stato avanzamento lavori fondazioni</li>' +
            '<li>Controllo armature e casserature piano terra</li>' +
            '<li>Coordinamento squadre per getto calcestruzzo previsto domani</li>' +
            '</ul>' +
            '<p><strong>Note:</strong> Necessario aggiornare cronoprogramma per maltempo previsto.</p>'
        },
        {
          title: 'Consegna Materiali',
          description: 'Template per registrare arrivo materiali in cantiere',
          content: '<h3>Registro Consegna Materiali</h3>' +
            '<p><strong>Data e ora:</strong> {{date.current}}</p>' +
            '<p><strong>Materiali ricevuti:</strong></p>' +
            '<table style="width: 100%; border-collapse: collapse; border: 1px solid #ddd;">' +
            '<tr><th style="border: 1px solid #ddd; padding: 8px;">Materiale</th><th style="border: 1px solid #ddd; padding: 8px;">Quantità</th><th style="border: 1px solid #ddd; padding: 8px;">Fornitore</th></tr>' +
            '<tr><td style="border: 1px solid #ddd; padding: 8px;">[MATERIALE]</td><td style="border: 1px solid #ddd; padding: 8px;">[QUANTITÀ]</td><td style="border: 1px solid #ddd; padding: 8px;">[FORNITORE]</td></tr>' +
            '</table>' +
            '<p><strong>Verifiche effettuate:</strong></p>' +
            '<ul>' +
            '<li>Corrispondenza con ordine di acquisto</li>' +
            '<li>Integrità del materiale</li>' +
            '<li>Documentazione tecnica allegata</li>' +
            '</ul>'
        },
      ],
      images_upload_handler: (blobInfo, _progress) => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = () => reject('Failed to read file as base64')
        reader.readAsDataURL(blobInfo.blob())
      }),
      setup: (editor) => {
        editor.ui.registry.addMenuButton('insertvar', {
          text: 'Insert Variable',
          tooltip: 'Insert Variable',
          icon: 'plus', // You can change this to any available icon
          fetch: (callback) => {
            const items = [
              { type: 'menuitem', text: 'User Full Name', onAction: () => editor.insertContent('{{user.full_name}}') },
              { type: 'menuitem', text: 'User Email', onAction: () => editor.insertContent('{{user.email}}') }
            ]
            callback(items)
          }
        })
      }
    })
  }

  disconnect() {
    window.hugerte.remove()
  }
}
