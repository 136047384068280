import { select_organization } from './select_organization/it'
import { select_project } from './select_project/it'
import { select_role } from './select_role/it'

export const form = {
  form: {
    select_organization,
    select_project,
    select_role,
  }
}
