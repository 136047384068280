import { Controller } from '@hotwired/stimulus'

import { debounce } from '../../utils/timeUtils'
import { TirinnovoDrawing } from './lib/tirinnovo_drawing'
import { ApiService } from '../../services/apiService'
import { lazyI18n } from '../../utils/lazyI18n'
import { radioTargetsValue } from '../../utils/htmlUtils'

// Connects to data-controller="draw--form"
export default class extends Controller {
  static values = {
    siteId: String,
    formId: String,
  }
  static targets = [
    'showInModalOnly',
    'blueprintData', 'blueprintReferenceValue', 'drawBtn',
    'drawingId', 'drawingTitle', 'drawingType', 'drawingBlueprintId', 'drawingShapes', 'total', 'totalSelected',
  ]

  blueprintDataTargetConnected() {
    this.blueprint = JSON.parse(this.blueprintDataTarget.value)
    this.drawingBlueprintIdTarget.value = this.blueprint.id
  }

  drawingTitleTargetConnected() {
    this.launchDrawingBoard()
    this.loadBlueprint()
    let drawing = {
      id: this.drawingIdTarget.value,
      drawing_type: radioTargetsValue(this.drawingTypeTargets),
      blueprint_id: null,
      title: null,
      shapes: JSON.parse(this.drawingShapesTarget.value),
      total: 0,
    }
    this.drawingIdTarget.value = drawing.id || ''
    this.drawingShapesTarget.value = JSON.stringify(drawing.shapes)
    this.drawer.loadShapes(drawing.drawing_type, drawing.shapes)
    this.drawer.drawingType = drawing.drawing_type
    this.drawer.resetView()
  }

  showInModalOnlyTargetConnected() {
    this.drawingModalEl = document.getElementById('drawingModal')
    if (this.drawingModalEl?.dataset?.formId) {
      this.showInModalOnlyTarget.classList.remove('d-none')
    }
  }

  connect() {
    this.debouncedUpdateRemoteBlueprint = debounce(this.updateRemoteBlueprint.bind(this), 100)
  }

  disconnect() {
    window.modal?.hide()
  }

  onDrawBtnClick() {
    this.drawer.onDrawBtnClick()
  }

  switchDrawingType(e) {
    this.drawer.switchDrawingType(e.target.value)
  }

  updateBackgroundOpacityChange(e) {
    this.drawer.updateBackgroundOpacity(e.target.value)
  }

  updateGridOpacityChange(e) {
    this.drawer.updateGridOpacity(e.target.value)
  }

  updateReferenceValue(e) {
    const value = e.target.value
    this.blueprint.reference_value = value
    this.drawer.updateReferenceValue(value)
    this.debouncedUpdateRemoteBlueprint()
  }

  onUpdateReferencePoints(points) {
    this.blueprint.reference_points = points
    this.updateRemoteBlueprint()
  }

  onUpdatePoints(shapes) {
    const updatedShapes = JSON.stringify(shapes)
    if (this.drawingShapesTarget.value != updatedShapes) {
      this.drawingShapesTarget.value = updatedShapes
      this.saveDrawing()
    }
  }

  onTotalChange({ total, totalSelected }) {
    const output = {
      total: (total || 0).toFixed(2),
      totalSelected: (totalSelected || 0).toFixed(2),
    }
    this.totalTarget.value = output.total
    this.totalSelectedTarget.innerHTML = output.totalSelected > 0 ? output.totalSelected : ''
  }

  resetViewClick() {
    this.drawer.resetView()
  }

  async onUploadBlueprintFile(event) {
    const file = event.target.files[0]
    if (!file) return

    const formData = new FormData()
    formData.append("authenticity_token", document.querySelector('meta[name=csrf-token]').content);
    formData.append("blueprint[file]", file)

    fetch(`/sites/${this.siteIdValue}/blueprints`, {
      method: 'POST',
      headers: { Accept: 'text/vnd.turbo-stream.html' },
      body: formData,
    })
      .then(response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html)
        const jsonBlueprint = (new DOMParser()).parseFromString(html, "text/xml").querySelector('[data-draw--form-target="blueprintData"]').getAttribute('value')
        this.blueprint = JSON.parse(jsonBlueprint)
        this.loadBlueprint()
      })
  }

  async loadBlueprint() {
    if (!this.blueprint.id || !this.blueprint.file_url) {
      this.drawer.resize()
      this.drawer.drawGrid()
      return
    }

    document.getElementById('loading-overlay').classList.remove('d-none')
    const fileResponse = await fetch(this.blueprint.file_url)
    document.getElementById('loading-overlay').classList.add('d-none')
    this.blueprintReferenceValueTarget.value = this.blueprint.reference_value

    this.blueprintFile = await fileResponse.blob()
    this.drawer.loadBlueprint({
      file: this.blueprintFile,
      reference_points: this.blueprint.reference_points,
      reference_value: this.blueprint.reference_value,
    })
  }

  launchDrawingBoard() {
    this.drawer?.dispose()
    this.drawer = new TirinnovoDrawing({
      drawType: 'area',
      blueprint: this.blueprint,
      onTotalChange: this.onTotalChange.bind(this),
      onUpdateReferencePoints: debounce(this.onUpdateReferencePoints.bind(this), 100),
      onUpdatePoints: debounce(this.onUpdatePoints.bind(this), 100),
      initialShapes: this.drawingShapesTarget.value,
      drawBtn: this.drawBtnTarget,
    })
  }

  async updateRemoteBlueprint() {
    if (!this.blueprint.id) { return }
    await ApiService.patch(`/sites/${this.siteIdValue}/blueprints/${this.blueprint.id}`, { blueprint: this.blueprint })
  }

  async editBlueprintClick(event) {
    const currentTitle = event.target.closest('a').dataset.title
    const title = prompt(lazyI18n("Nuovo nome dell'immagine di riferimento"), currentTitle)
    if (title) {
      const blueprintId = event.currentTarget.dataset.blueprint_id
      const formData = new FormData();
      formData.append('authenticity_token', document.querySelector('meta[name=csrf-token]').content);
      formData.append('blueprint[title]', title);

      fetch(`/sites/${this.siteIdValue}/blueprints/${blueprintId}`, {
        method: 'PATCH',
        headers: { Accept: 'text/vnd.turbo-stream.html' },
        body: formData,
      })
        .then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html))
    }
  }

  async saveDrawing() {
    const rawFormData = Object.fromEntries(new FormData(document.getElementById('drawing_form')))
    const formData = new FormData()
    formData.append('authenticity_token', document.querySelector('meta[name=csrf-token]').content)
    formData.append('drawing[blueprint_id]', rawFormData['drawing[blueprint_id]'])
    formData.append('drawing[shapes]', rawFormData['drawing[shapes]'])
    formData.append('drawing[drawing_type]', rawFormData['drawing[drawing_type]'])
    formData.append('drawing[total]', rawFormData['drawing[total]'])

    const drawingId = rawFormData['drawing[id]']
    const url = drawingId ? `/sites/${this.siteIdValue}/drawings/${drawingId}` : `/sites/${this.siteIdValue}/drawings`
    fetch(url, {
      method: drawingId ? 'PATCH' : 'POST',
      headers: { Accept: 'text/vnd.turbo-stream.html' },
      body: formData,
    })
      .then(response => response.text())
      .then(html => {
        if (!drawingId) {
          Turbo.renderStreamMessage(html)
        }
      })
  }

  async editDrawingClick(event) {
    const title = prompt(lazyI18n('Come vuoi chiamare questo disegno?'), this.drawingTitleTarget.innerHTML)
    if (title) {
      const drawingId = event.currentTarget.dataset.drawing_id
      const formData = new FormData();
      formData.append('authenticity_token', document.querySelector('meta[name=csrf-token]').content);
      formData.append('drawing[title]', title);
      formData.append('drawing[id]', drawingId);

      fetch(`/sites/${this.siteIdValue}/drawings`, {
        method: 'POST',
        headers: { Accept: "text/vnd.turbo-stream.html" },
        body: formData,
      })
        .then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html))
    }
  }

  insertTotal() {
    // Warning! When changing this please also look at '_form_manual_measurements.html.erb' view

    if (!(this.totalTarget.value > 0)) {
      alert(lazyI18n('Per inserire il totale è necessario disegnare un area, linea o perimetro'))
      return
    }

    document.querySelector(`#site_task_measurement_${this.formIdValue}`)?.dispatchEvent(
      new CustomEvent('updateDrawing', {
        detail: {
          uniqueId: this.formIdValue,
          drawingId: this.drawingIdTarget.value,
          drawingTitle: this.drawingTitleTarget.innerHTML,
          total: this.totalTarget.value
        }
      })
    )

    document.getElementById('drawingModal').remove()
  }

  cloneDrawingClick(event) {
    const defaultTitle = event.currentTarget.dataset.defaultTitle
    const title = prompt(lazyI18n('Come vuoi chiamare questo disegno?'), defaultTitle)
    if (title) {
      const formData = new FormData();
      formData.append('drawing[title]', title);
      fetch(event.currentTarget.href, {
        method: 'POST',
        headers: {
          Accept: "text/vnd.turbo-stream.html",
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
        },
        body: formData
      })
        .then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html))
    }
  }
}
