const Constants = {
  referenceLineColor: '#FFA700',
  strokeWidth: 2,
  handleSizeRadius: 5,
  defaultReferencePoints: [{ x: 100, y: 100 }, { x: 200, y: 100 }],
  defaultReferenceValue: 100,
  gridPixelSize: 20,
  subGridRatio: 2,
  gridLineStyle: {
    stroke: '#ccc',
    selectable: false,
    evented: false,
    type: 'grid',
    opacity: 1
  }
}

export default Constants
