import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="utils--multi-checkboxes"
export default class extends Controller {
  static targets = ['selectAll', 'checkbox']

  connect() {
    this.element.addEventListener('change', this.handleChange)
  }

  disconnect() {
    this.element.removeEventListener('change', this.handleChange)
  }

  toggleAll() {
    const isChecked = this.selectAllTarget.checked
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = isChecked
    })
  }

  handleChange = (event) => {
    if (event.target.matches('[data-utils--multi-checkboxes-target="checkbox"]')) {
      const allChecked = this.checkboxTargets.every(cb => cb.checked)
      this.selectAllTarget.checked = allChecked
    }
  }
}
