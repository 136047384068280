import { select_organization } from './select_organization/en'
import { select_project } from './select_project/en'
import { select_role } from './select_role/en'

export const form = {
  form: {
    select_organization,
    select_project,
    select_role,
  }
}
