import { contact_roles } from './contact_roles/it'
import { no_selected_permission_role } from './no_selected_permission_role/it'
import { role_permissions_content } from './role_permissions_content/it'
import { role_permissions_page } from './role_permissions_page/it'

export const edit_permissions = {
  contact_roles,
  no_selected_permission_role,
  role_permissions_content,
  role_permissions_page,
}
