import { Controller } from '@hotwired/stimulus'
import { createRoot } from 'react-dom/client'
import ErrorBoundary from './components/Pages/ErrorBoundary'

// Connects to data-controller="utils--react-loader"
export default class extends Controller {
  static values = { component: String }

  async connect() {
    const Component = await this.importComponent()
    createRoot(this.element).render(
      <>
        <ErrorBoundary>
          <Component />
        </ErrorBoundary>
      </>
    )
  }

  importComponent() {
    return import(`./components/Pages/${this.componentValue || 'Empty'}/index.jsx`).then(module => module.default)
  }
}
