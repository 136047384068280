export const role_permissions_content = {
  actions: 'Azioni',
  permission_group: {
    organization: 'Permessi globali',
    project_global: 'Permessi per tutti i cantieri',
    project_specific: 'Permessi di cantiere',
  },
  reset: 'Ripristina',
  resource: 'Risorsa',
  role: 'Ruolo',
  select_role: 'Seleziona ruolo',
  delete_role: 'Elimina ruolo',
  no_role: 'Nessun permesso per questo ruolo.',
}
