import i18next from 'i18next'
import { addNotification } from '../helpers/notificationHelper'
import { urlWithQuery } from '../utils/uriUtils'

const routes = {
  signInPath: '/users/sign_in',
}

class UnprocessableEntityError extends Error {
  constructor(body) {
    super('API returned error status 422')
    this.status = 422
    this.body = body
  }
}

class ApiError extends Error {
  constructor(status, body) {
    super(`API returned error status ${status}`)
    this.status = status
    this.body = body
  }
}

const defaultOptions = {
  headers: {
    Accept: 'application/json; charset=utf-8',
    'Content-Type': 'application/json; charset=utf-8',
  },
  request: {
    notifyErrors: true,
  },
}

export class ApiService {
  static async get(path, params = undefined, options = defaultOptions) {
    return await this.request('GET', urlWithQuery(path, params), undefined, options)
  }

  static async post(path, body, options = defaultOptions) {
    return await this.request('POST', path, body, options)
  }

  static async patch(path, body, options = defaultOptions) {
    return await this.request('PATCH', path, body, options)
  }

  static async put(path, body, options = defaultOptions) {
    return await this.request('PUT', path, body, options)
  }

  static async destroy(path, body, options = defaultOptions) {
    return await this.request('DELETE', path, body, options)
  }

  static getCSRFConfig() {
    const csrfParamMeta = document.querySelector('meta[name=csrf-param]')
    const csrfTokenMeta = document.querySelector('meta[name=csrf-token]')
    const csrfParam = csrfParamMeta ? csrfParamMeta.content : 'csrf_token'
    const csrfToken = csrfTokenMeta ? csrfTokenMeta.content : 'unavailable'

    return { param: csrfParam, token: csrfToken }
  }

  static async request(method, path, body, options) {
    try {
      const res = await fetch(path, {
        method,
        credentials: 'same-origin',
        cache: 'no-cache',
        headers: options.headers,
        body: this.processRequestBody(body),
        redirect: 'follow',
        referrer: 'no-referrer',
      })

      return await this.processResponseBody(res)
    } catch (error) {
      this.handleApiError(error, method, path, body, options);
    }
  }

  static verifyBody(body) {
    if (body === undefined) return
    const { param, token } = this.getCSRFConfig()
    if (body instanceof FormData) {
      body.append(param, token)
      return body
    }
    return { ...body, [param]: token }
  }

  static processRequestBody(body) {
    if (body === undefined) return
    const verifiedBody = this.verifyBody(body)
    if (body instanceof FormData) return verifiedBody
    return JSON.stringify(verifiedBody)
  }

  static async processResponseBody(res) {
    if (res.status === 204) return {}
    const contentType = res.headers.get('content-type')
    const isJsonResponse = contentType && contentType.includes('application/json')
    const data = isJsonResponse ? await res.json() : await res.blob()

    if (res.status === 401) {
      window.location.replace(routes.signInPath)
    } else if (res.status === 422) {
      throw new UnprocessableEntityError(data)
    } else if (res.status !== 200 && res.status !== 201) {
      throw new ApiError(res.status, data)
    }
    return data
  }

  static handleApiError(error, method, path, body, options) {
    console.error('API Error:', method, path, body, options);

    if (options.request.notifyErrors) {
      addNotification(
        '<span class="fa fa-exclamation-triangle me-2"></span>' +
          i18next.t('command_result.error_occurred'), 'bg-danger', 'gantt-task-saved')
    }

    throw error;
  }
}
